import { fetcher } from '@lib/fetcher';

import { RESEND_ACCOUNT_CONFIRMATION } from './ResendAccountConfirmation.mutation';
import { transformResendConfirmationEmail } from './ResendAccountConfirmation.transform';
import { Variables } from './types';

export default function useResendEmailConfirmation() {
    function mutate(variables: Variables) {
        return fetcher(RESEND_ACCOUNT_CONFIRMATION, variables).then((data) => {
            return transformResendConfirmationEmail(data);
        });
    }

    return { mutate };
}
