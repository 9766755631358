import Accordion, {
  AccordionItem,
} from '@components/molecules/Accordion/Accordion';
import react, { Fragment, useEffect, useState } from 'react';
import { useAuth, withAuth } from '@providers/AuthProvider/AuthProvider';

import Button from '@components/atoms/Button/Button';
import HeaderSearch from '../HeaderSearch/HeaderSearch';
import Image from 'next/image';
import Link from 'next/link';
import { ROUTES } from '@constants/routes';
import { USAID_ROLES_LIST } from '@constants/utils';
import classNames from 'classnames';
import cn from 'classnames';
import styles from './Header.module.scss';
import useHeaderCorporate from '@data/strapi/HeaderCorporate/Query/GetHeaderCorporate/GetHeaderCorporateQuery';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

const Header = ({ initialData, variables }) => {
  const { t } = useTranslation('common');

  const head: any = useHeaderCorporate(initialData, variables);
  const { header, error } = head;

  const router = useRouter();

  const searchPage = router.route.indexOf('search') >= 0;

  const [menuActive, setMenuActive] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const { me, canLoad, loadingMe, logout } = useAuth() as any;

  const headerOnClick = (isActive, search) => {
    if (isActive) {
      document.documentElement.classList.add('no-scroll');
    } else {
      document.documentElement.classList.remove('no-scroll');
    }

    if (search) {
      setSearchActive(isActive);
    } else {
      setMenuActive(isActive);
    }
  };

  if (error) return <pre>{error}</pre>;

  return (
    <>
      <HeaderSearch
        isOpen={searchActive}
        onClose={() => {
          setSearchActive(false);
          document.documentElement.classList.remove('no-scroll');
        }}
      />
      <header className={classNames(styles.navbar)}>
        <Link
          href="/"
          aria-label="Read more about USAID"
          rel="noopener noreferrer"
          className="flex-shrink-1"
        >
          <Image
            width={150}
            height={40}
            src="/assets/logo/logo.svg"
            alt="USAID logo"
          />
        </Link>
        <nav className="d-none d-lg-flex d-xl-flex">
          {header?.menu &&
            header?.menu.map((item, index) => {
              return (
                <div
                  key={'nav_menu_li_' + index}
                  className={cn(styles.menuItem, 'd-inline-block')}
                >
                  <Button
                    key={'nav_menu_li_' + index}
                    variation="icon"
                    label={item.label}
                    iconRight={
                      item?.links && item?.links.length ? (
                        <i className="icon-down"></i>
                      ) : (
                        ''
                      )
                    }
                    url={item?.links && item?.links.length ? '' : item.url}
                    className={'color-primary-100'}
                  />
                  {item?.links && item?.links.length > 0 && (
                    <div className={cn(styles.subMenu)}>
                      <ul className="nav-container">
                        {item.links.map((subitem, index) => {
                          return (
                            <li
                              key={'subitem_' + index}
                              className={styles.menuItem}
                            >
                              <Button
                                key={'subitem_' + item.label + index}
                                variation="icon"
                                label={subitem.label}
                                url={subitem.url}
                                iconLeft={
                                  subitem?.icon ? (
                                    <i className={subitem.icon}></i>
                                  ) : (
                                    ''
                                  )
                                }
                                className={'color-primary-100'}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              );
            })}

          <div className={styles.divider}></div>

          {/* login */}
          {!me ? (
            <div
              className={cn(
                styles.menuItem,
                'd-inline-block d-none d-md-inline-block'
              )}
            >
              {canLoad && (
                <Link
                  className="color-primary-100 fw-semi_bold"
                  href={'/login'}
                >
                  Login
                </Link>
              )}
            </div>
          ) : (
            <div
              className={cn(
                styles.menuItem,
                'd-inline-block d-none d-md-inline-block'
              )}
            >
              <Button
                variation="icon"
                label={`${me.firstName} ${me.lastName}${
                  me.role === USAID_ROLES_LIST.USAID_STAFF
                    ? ' [STAFF ACCOUNT]'
                    : ''
                }`}
                iconLeft={
                  <i
                    className={
                      me.role === USAID_ROLES_LIST.USAID_STAFF
                        ? 'icon-staff-user'
                        : 'icon-user'
                    }
                  ></i>
                }
                // url={item?.links && item?.links.length ? "" : item.url}
                className={'color-primary-100'}
              />
              <div className={cn(styles.subMenu)} data-alignment="right">
                <ul className="nav-container">
                  {me.role !== USAID_ROLES_LIST.USAID_STAFF && (
                    <li className={styles.menuItem}>
                      <Button
                        variation="icon"
                        iconLeft={<i className="icon-partner-directory"></i>}
                        label={'Update my Organization'}
                        url={ROUTES.ORGANIZATION.PHOTO}
                        className={'color-primary-100'}
                      />
                    </li>
                  )}
                  <li className={styles.menuItem}>
                    <Button
                      variation="icon"
                      iconLeft={<i className="icon-settings"></i>}
                      label={'Account settings'}
                      url={ROUTES.USER.SETTINGS}
                    />
                  </li>
                  <li className={styles.menuItem}>
                    <Button
                      variation="icon"
                      iconLeft={<i className="icon-logout"></i>}
                      label={'Logout'}
                      url={'/login'}
                      onClick={() => {
                        logout();
                      }}
                    />
                  </li>
                </ul>
              </div>
            </div>
          )}
        </nav>
        <div className="d-flex align-items-center">
          {!searchPage && (
            <div className="flex-grow-1 flex-md-grow-0 flex-md-shrink-1 text-end">
              <span
                className={`${styles.searchTrigger} d-inline-block ms-4`}
                role="button"
                aria-label="Search trigger"
                onClick={() => headerOnClick(!searchActive, true)}
              >
                <i className="icon-search"></i>
              </span>
            </div>
          )}
          {!me && canLoad && (
            <Button
              variation="secondary"
              label={t('account.list_org')}
              url={'/'}
              disabled={canLoad}
              className="ms-3 d-none d-md-flex me-0"
            />
          )}
          {/* hamburger */}
          <div className="d-inline-block d-xs-block d-md-block d-lg-none align-middle ms-4">
            <div
              className={cn(styles.hamburger, menuActive && styles.open)}
              onClick={() => headerOnClick(!menuActive, false)}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </header>
      <div className={cn(styles.menuMobile, menuActive && styles.open)}>
        <div className="h-100">
          <Accordion>
            {header?.menu &&
              header?.menu.map((item, index) => {
                if (item?.links.length === 0) {
                  return (
                    <div
                      key={`mobile-menu-${index}`}
                      className={'d-table mt-3 mb-3'}
                    >
                      <Button
                        key={`mobile-menu-${index}`}
                        variation="icon"
                        label={item.label}
                        url={item.url}
                        iconLeft={
                          item?.icon ? <i className={item.icon}></i> : ''
                        }
                        className={'color-primary-100 mt-2'}
                      />
                    </div>
                  );
                }
                return (
                  <Fragment key={`mobile-${index}`}>
                    {item?.links && item?.links.length > 0 ? (
                      <AccordionItem
                        key={`mobile-menu-${index}`}
                        title={item.label}
                      >
                        {item.links.map((subitem, j) => {
                          return (
                            <div
                              key={`mobile-menu-${index}-${j}`}
                              className={'d-table'}
                            >
                              <Button
                                key={`mobile-menu-${index}-${j}`}
                                variation="icon"
                                label={subitem.label}
                                url={subitem.url}
                                iconLeft={
                                  subitem?.icon ? (
                                    <i className={subitem.icon}></i>
                                  ) : (
                                    ''
                                  )
                                }
                                className={'color-primary-100 mt-2'}
                              />
                            </div>
                          );
                        })}
                      </AccordionItem>
                    ) : (
                      <AccordionItem
                        key={`mobile-menu-${index}`}
                        title={item.label}
                      >
                        <Button
                          variation="icon"
                          label={item.label}
                          url={item.url}
                          className={'color-primary-100'}
                        />
                      </AccordionItem>
                    )}
                  </Fragment>
                );
              })}
            {me ? (
              <AccordionItem title={`${me.firstName} ${me.lastName}`}>
                <div className={'d-table'}>
                  <Button
                    variation="icon"
                    iconLeft={<i className="icon-partner-directory"></i>}
                    label={'Update my Organization'}
                    url={ROUTES.ORGANIZATION.PHOTO}
                    className={'color-primary-100'}
                  />
                </div>
                <div className={'d-table'}>
                  <Button
                    variation="icon"
                    iconLeft={<i className="icon-settings"></i>}
                    label={'Account settings'}
                    url={'/login'}
                  />
                </div>
                <div className={'d-table'}>
                  <Button
                    variation="icon"
                    iconLeft={<i className="icon-logout"></i>}
                    label={'Logout'}
                    url={'/login'}
                    onClick={() => {
                      logout();
                    }}
                  />
                </div>
              </AccordionItem>
            ) : (
              <div className={'d-table mt-3 mb-3'}>
                <Button
                  variation="icon"
                  label={'Login'}
                  url={'/login'}
                  className={'color-primary-100 mt-2'}
                />
              </div>
            )}
          </Accordion>
        </div>
        <div className="text-center">
          {!me && (
            <Button
              variation="secondary"
              label={t('account.list_org')}
              url={'/'}
              className="ms-3"
              disabled={!canLoad}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
