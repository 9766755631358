import Notification from '@components/common/Notification/Notification';
import { useMemo } from 'react';
import { useIdleContext } from '@providers/IdleTimerLocalProvider/IdleTimerLocalProvider';
import useTranslation from 'next-translate/useTranslation';

const IdleTimerNotification = () => {
  const {
    handleDismiss,
    hasBeenIdle,
    notificationVisible,
    notifificationDismissed,
    timeoutDurationMinutes,
  } = useIdleContext();
  const { t } = useTranslation('common');

  const notification = useMemo(() => {
    if (!hasBeenIdle || !notificationVisible || notifificationDismissed) {
      return null;
    }

    return (
      <Notification
        text={ t('account.system_timeout', { system_timeout_duration: timeoutDurationMinutes }) }
        type='info'
        showClose={ true }
        onClose={ handleDismiss }
      />
    );
  }, [hasBeenIdle, notificationVisible, notifificationDismissed, t]);

  return notification;
};

export default IdleTimerNotification;
