'use client'
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useAuth } from '@providers/AuthProvider/AuthProvider';
import { useIdleTimer } from 'react-idle-timer';
import { getItem, saveItem, removeItem } from '@utils/LocalStorage';

const timeoutDurationMinutes = (process.env.NEXT_PUBLIC_SYSTEM_TIMEOUT_MINUTES || '0');
const timeOutMs = parseFloat(timeoutDurationMinutes) * 60 * 1000;
const storageHasBeenIdle = 'hasBeenIdle';
const storageIdleNotificationVisible = 'idleNnotificationVisible';
const storageNotificationDismissed = 'idleNotificationDismissed';
export const storageResetTimer = 'idleResetTimer';

interface IdleContextProps {
  handleDismiss: () => void,
  hasBeenIdle: boolean,
  isIdle: boolean;
  notificationVisible: boolean;
  notifificationDismissed: boolean;
  timeoutDurationMinutes: string,
}

const IdleContext = createContext<IdleContextProps>({
  handleDismiss: () => {},
  hasBeenIdle: false,
  isIdle: false,
  notificationVisible: false,
  notifificationDismissed: false,
  timeoutDurationMinutes: timeoutDurationMinutes,
});

export const useIdleContext = () => useContext(IdleContext);

function IdleTimerLocalProvider({ children }) {

  const { me, logout } = useAuth() as any;
  const [isIdle, setIsIdle] = useState(false);
  const [hasBeenIdle, setHasBeenIdle] = useState(false);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [notifificationDismissed, setNotifificationDismissed] = useState(false);

  useEffect(() => {
    const storedHasBeenIdle = getItem(storageHasBeenIdle) === 'true';
    const storedIdleNotificationVisible = getItem(storageIdleNotificationVisible) === 'true';
    const storedIdleNotificationDismissed = getItem(storageNotificationDismissed) === 'true';
    setHasBeenIdle(storedHasBeenIdle);
    setNotificationVisible(storedIdleNotificationVisible);
    setNotifificationDismissed(storedIdleNotificationDismissed);
  }, [me]);

  /**
  * Handler for onIdle event.
  *
  * When user goes idle, set state for isIdle and storage variables
  * indicating the user has been idle, and whether the IdleNotification
  * should be visible.
  *
  * Remove any 'dismissed' variable previously set in state. Every
  * time the user goes idle, they will have to re-dismiss the notification.
  *
  * Log the user out using AuthProvider's logout method.
  */
  const handleOnIdle = () => {
    if (me) {
      // Set state.
      setIsIdle(true);
      setHasBeenIdle(true);

      // Storage.
      saveItem(storageHasBeenIdle, 'true');
      saveItem(storageIdleNotificationVisible, 'true');

      // Every time going idle, the notification must be dismissed.
      removeItem(storageNotificationDismissed);

      // Log the user out.
      logout();
    }
  };

  /**
  * Dismiss handler for Notification.
  * Sets state and storage for
  *  - notification dismissed as true
  *  - notification visible as false
  */
  const handleDismiss = () => {
    resetIdleStateAndStorage();
    setNotifificationDismissed(true);
    saveItem(storageNotificationDismissed, 'true');
  };

  /**
  * Reset state and session storage.
  */
  const resetIdleStateAndStorage = useCallback(() => {

    // Reset state to false.
    setHasBeenIdle(false);
    setNotificationVisible(false);
    setNotifificationDismissed(false);
    // Remove storage.
    removeItem(storageHasBeenIdle);
    removeItem(storageIdleNotificationVisible);
    removeItem(storageNotificationDismissed);
    removeItem(storageResetTimer);
  }, [
    storageHasBeenIdle,
    storageIdleNotificationVisible,
    storageNotificationDismissed,
    storageResetTimer,
    setHasBeenIdle,
    setNotificationVisible,
    setNotifificationDismissed,
    removeItem,
  ]);


  const idleTimer = timeOutMs > 0 ? useIdleTimer({
    timeout: timeOutMs,
    onIdle: handleOnIdle,
    debounce: 500,
    eventsThrottle: 500,
    startOnMount: !!me,
    stopOnIdle: true,
    startManually: !me,
    crossTab: true,
    syncTimers: 100,
  }) : null;

  useEffect(() => {
    if (!idleTimer) {
      // Do not start idle timer if the timeout value is 0. It means either
      // the env var was set at zero or is missing.
      resetIdleStateAndStorage();
      return;
    }
    if (me) {

      // Logged in.
      idleTimer.start();
      setHasBeenIdle(false);
      removeItem(storageIdleNotificationVisible);
      removeItem(storageResetTimer);
    } else {
      // Not logged in.
      const lastResetTime = getItem(storageResetTimer) || '6';

      if (lastResetTime && (Date.now() - (parseInt(lastResetTime, 10) / 1000)) < 5) {
        // User has just logged out using the logout button and did not
        // trigger the idleTimer.
        resetIdleStateAndStorage();
      }

      // For logged out users, reset and immediately pause the timer.
      idleTimer.reset();
      idleTimer.pause();

      // If the user has been idle at least once
      // and if the idle notification was not specifically dismissed
      // set storage visibility as 'true', ensuring the notification
      // must be dismissed again.
      if (hasBeenIdle) {
        const storageNotificationDismissed = getItem(storageIdleNotificationVisible) === 'false';

        if (!storageNotificationDismissed) {
          saveItem(storageIdleNotificationVisible, 'true');
        }
      }
    }
  }, [me]);

  return (
    <IdleContext.Provider value={{
      handleDismiss,
      hasBeenIdle,
      isIdle,
      notificationVisible,
      notifificationDismissed,
      timeoutDurationMinutes,
    }}>
    {children}
    </IdleContext.Provider>
  );
}

export default IdleTimerLocalProvider;