import { v4 as uuid } from 'uuid';

export function getProfilePhotoUrl(id) {
    const url = `${
        process.env.NEXT_PUBLIC_PROFILE_IMAGE_URL
    }/${id}.png?cached-version${uuid()}`;
    // console.log('Generated profile photo URL:', url);
    return url;
}

export async function profilePhotoExists(fullUrl: string) {
    // const url = `${process.env.NEXT_PUBLIC_PROFILE_IMAGE_URL}/${id}.png`;
    // console.log('Checking if profile photo exists:', fullUrl);
    return new Promise<boolean>((resolve) => {
        if (!fullUrl) {
          // If undefined or empty, do not even try to add it as the img.src.
          resolve(false);
        } else {
          const img = new Image();
          img.onload = function () {
              resolve(true);
          };
          img.onerror = function () {
              resolve(false);
          };
          img.src = fullUrl;
        }
    });
  }