import { fetcher } from "@lib/fetcher";

import { LOG_IN } from "./LogIn.mutation";
import { transformLogIn } from "./LogIn.transform";
import { Variables } from "./types";

export default function useLogin() {
  function mutate(variables: Variables) {
    return fetcher(LOG_IN, variables)
      .then((data) => {
        return transformLogIn(data);
      })
      .catch((error) => {
        if (variables && variables.data.googleId) {
          if (error && JSON.stringify(error).includes("\"message\":\"Wrong Credentials\"")) {
            console.log("Login.gov auth detected");
            return ({
              error: "This Login.gov account is not registered with us. Please sign up first."

            });
          }
        }
        // console.log("useLogin error variables", variables);
        console.log("useLogin mutate error", error);
        throw error;
      });
  }

  return { mutate };
}
