import Loading from "@components/common/Loading/Loading";
import { ROUTES } from "@constants/routes";
import useMe from "@data/backoffice/Auth/Query/GetMe/GetMeQuery";
import useLogin from "@data/backoffice/Registration/Mutations/LogIn/LogIn";
import { isClientSide } from "@utils/isClientSide";
import { removeItem, saveItem, getItem } from "@utils/LocalStorage";
import { saveItem as sessionSaveItem, getItem as sessionGetItem } from "@utils/SessionStorage";
import { signOut, signout, useSession } from "next-auth/client";
import { useRouter } from "next/router";
import React, { createContext, useContext, useState } from "react";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
import { storageResetTimer } from "@providers/IdleTimerLocalProvider/IdleTimerLocalProvider";
import { Object } from "lodash";

type QueryParams = object | undefined;

interface IAuthContext {
  login: (values: any) => Promise<boolean>;
  logout: (shouldRedirect?: boolean | null | undefined) => Promise<boolean>
  softLogout: () => Promise<boolean>
  cisLogout: (queryParams?: QueryParams) => Promise<boolean>
  me: any;
  canLoad: boolean;
  loadingMe?: boolean;
  isRegistering: boolean | null;
  storageSetIsRegistering: (isRegistering: boolean | string | null) => void;
  storageIsRegistering: () => boolean | null;
}



const AuthContext = createContext<IAuthContext | null>(null);

function AuthProvider({ children }) {
  // Fetch authenticated user
  const { me, canLoad, mutate: mutateMe, loadingMe } = useMe();
  // Login
  const { mutate: mutateLogin } = useLogin();

  const [isRegistering, setIsRegistering] = useState<boolean | null>(null);
  const router = useRouter();
  const storageSetIsRegistering = (isRegistering: boolean | null): void => {
    if (isRegistering) {
      saveItem('isRegistering', isRegistering);
    } else {
      // If falsy, remove it from sessionStorage.
      removeItem('isRegistering');
    }

    setIsRegistering(isRegistering);
  }

  const storageIsRegistering = (): boolean | null  => {
    const item = getItem('isRegistering');
    // If a string, return boolean checking for true, otherwise null if not set.
    const status = item?.length ? item === 'true' : null;

    return status;
  }

  /**
   * Common logout tasks for both a soft logout
   */
  const handleLogout = async (shouldRedirect: boolean | null | undefined = true ) => {
    await signOut({ redirect: !!shouldRedirect });

    removeItem("token");
    removeItem("loginGovAuth");
    mutateMe();
    saveItem(storageResetTimer, Date.now());
    return true;
  }

  /**
   * Log users out of CIS / Login.gov via API endpoint.
   */
  const handleCisLogout = async (queryParams: QueryParams = {}) => {
    // End CIS session. May not end a Login.gov session immediately.
    let logoutApiRoute = `${window.location.origin}/api/auth/logout`;

    const queryString = new URLSearchParams(
      queryParams as Record<string, string>).toString();

      if (queryString) {
        logoutApiRoute = logoutApiRoute.concat(`?${queryString}`);
      }

    let locale = sessionGetItem('logoutLocale') || '';
    if (!locale) {
      // If storage locale didn't exist, check the actual locale.
      // This could be the /logout page where after being redirected
      // from login.gov, the default locale would always be English.
      locale = router?.locale || '';
      if (locale && locale !== 'en') {
        sessionSaveItem('logoutLocale', locale);
      }
    }

    sessionSaveItem('logoutRedirect', router.asPath);

    setTimeout(() => {
      window.location.href = logoutApiRoute;
    }, 0);
    return true;
}

  return (
    <AuthContext.Provider
      value={{
        async login(values) {
          //get token from api
          const result = await mutateLogin({ data: values });

          if (result?.me) {
            const { accessToken } = result;
            saveItem("token", `Bearer ${accessToken}`);
            await mutateMe();
            return true;
          }
          return false;
        },
        async cisLogout(queryParams?: QueryParams) {
          await handleCisLogout(queryParams);
          return true;
        },
        async softLogout() {
          await handleLogout(false);
          return true;
        },
        async logout(shouldRedirect = true) {
          await handleCisLogout();
          await handleLogout(shouldRedirect);
          return true;
        },

        me,
        canLoad,
        loadingMe,
        storageSetIsRegistering,
        storageIsRegistering,
        isRegistering,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth(): IAuthContext | null {
  return useContext(AuthContext) as IAuthContext;
}

const useAuthStrict = (): IAuthContext => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthStrict must be used within an AuthProvider');
  }
  return context;
};



function withAuth(WrappedComponent, shouldRedirect = true) {
  return (props) => {
    const LoadingComponent = () => {
      return (
        <div className="m-auto" >
          <Loading label={""} />
        </div>
      );

    }
    const router = useRouter();
    const { me, canLoad } = useAuth() as {me: any, canLoad: boolean};
    // If first authentication check is still pending
    if (!canLoad)
      return (
        <LoadingComponent />
      );


    // if user is logged in return component with logged in user

    if (me) return <WrappedComponent {...props} loggedInUser={me} />;

    // checks whether we are on client / browser or server.
    if (isClientSide()) {
      router.replace({
        pathname: ROUTES.AUTHENTICATION.SIGN_IN,
        query: shouldRedirect ? { redirectTo: router.pathname } : undefined,
      });
      return (
        <LoadingComponent />
      );
    }

    return null;
  };
}

export { AuthProvider, useAuth, useAuthStrict, withAuth };
