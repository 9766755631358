import React, { useEffect, useRef } from 'react';

import Button from '@components/atoms/Button/Button';
import Heading from '@components/atoms/Heading/Heading';
import PortalComponent from '@components/common/PortalComponent/PortalComponent';
import { Props } from './types';
import classNames from 'classnames';
import styles from './Modal.module.scss';

const Modal = ({
  title,
  handleOk,
  handleCancel,
  labelOk,
  labelCancel,
  children,
  loading,
  className,
}: Props) => {
  const showFooter = handleOk || handleCancel;

  const wrapperRef = useRef<HTMLDivElement>(null);

  const cancelHelpers = () => {
    handleCancel && handleCancel();
    document.documentElement.classList.remove('overHidden-modal');
  };

  const okHelpers = () => {
    handleOk && handleOk();
    document.documentElement.classList.remove('overHidden-modal');
  };

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const node = modalRef.current;
    if (!node) return;

    document.documentElement.classList.add('overHidden-modal');

    const handleEsc = (e: KeyboardEvent) => {
      if (e.which === 27) {
        cancelHelpers();
      }
    };

    const handleOutside = (e: MouseEvent) => {
      if (e.target === node) {
        cancelHelpers();
      }
    };

    if (node) {
      node.addEventListener('click', handleOutside);
      document.documentElement.addEventListener('keydown', handleEsc);
    }

    return () => {
      document.documentElement.classList.remove('overHidden-modal');

      if (node) {
        node.removeEventListener('click', handleOutside);
        document.documentElement.removeEventListener('keydown', handleEsc);
      }
    };
  }, [modalRef]);

  return (
    <PortalComponent wrapperId={'modal-wrapper'}>
      <div ref={modalRef} className={classNames(styles.modal, className)}>
        <div ref={wrapperRef} className={styles.wrapper}>
          <div className={styles.modalHeader}>
            {title && <Heading semantic="span" size="h4" value={title} />}
            <i
              className={classNames(styles.closeButton, 'icon-cancel')}
              onClick={() => cancelHelpers()}
            ></i>
          </div>
          {children}

          {showFooter && (
            <div className={styles.actions}>
              {labelCancel && (
                <Button
                  variation="link"
                  label={labelCancel}
                  onClick={() => cancelHelpers()}
                  className="color-gray-50 m-0"
                  disabled={loading}
                />
              )}
              {labelOk && (
                <Button
                  variation="secondary"
                  label={labelOk}
                  onClick={() => okHelpers()}
                  className="m-0"
                  disabled={loading}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </PortalComponent>
  );
};

export default Modal;
